import "../styles/app.scss";

import "popper.js";
import { Alert, Collapse, Dropdown, Modal, Tab } from "bootstrap";

import "@fortawesome/fontawesome-free/js/fontawesome";
import "@fortawesome/fontawesome-free/js/solid";
import "@fortawesome/fontawesome-free/js/regular";
import "@fortawesome/fontawesome-free/js/brands";

// Sticky responsive header
const headerScrollOffset = 100;
window.onscroll = function () {
    changeHeaderClass();
};

function changeHeaderClass() {
    if (window.scrollY > headerScrollOffset) {
        document
            .getElementsByClassName("header-navigation")[0]
            .classList.add("is-scrolled");
    } else {
        document
            .getElementsByClassName("header-navigation")[0]
            .classList.remove("is-scrolled");
    }
}
